*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Lato", sans-serif;
}

body {
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__feed {
    width: 63rem;
    display: flex;
    flex-wrap: wrap;
    margin: -1rem;
    padding-bottom: 5rem;

    @media (max-width: 630px) {
      width: 42rem;
      display: flex;
      flex-wrap: wrap;
      margin: -1rem;
    }

    @media (max-width: 420px) {
      width: 21rem;
      display: flex;
      flex-wrap: wrap;
      margin: -1rem;
    }
  }
}

.header {
  padding: 3rem 0;

  &__title {
    font-size: 5rem;
    text-align: center;

    @media (max-width: 420px) {
      font-size: 3.5rem;
    }
  }

  &__link {
    color: inherit;
  }
}

.post {
  object-fit: cover;
  margin: 0.5rem;
}